import config from '../../../config'

import React from 'react'

const Hero = () => {
    return (
        <section className="py-4">
            {/* <div className="container col-xxl-10 px-4 py-5"> */ }
            <div className="container col-xxl-10 px-4">
                <div className="row flex-lg-row-reverse align-items-center g-5 py-5 justify-content-center">
                    <div className="col-10 col-sm-8 col-lg-6 text-center">
                        <i className="bi bi-coin special-text" style={{ fontSize: '250px' }}></i>
                    </div>
                    <div className="col-lg-6">
                        <h1 className="display-5 fw-bold lh-1 mb-3 title">Earn or Buy Points & Skip Ads</h1>
                        <p className="lead subtitle">Earn or purchase points and use them to enjoy ad-free downloads.</p>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                            <a href={config.MAGICPOINTS_WEBSITE_URL + '/register?source=magicfans'} className="btn btn-primary special-button" target="_blank">Get Started</a>
                            {/* <a href="scholarships" className="btn btn-primary btn-lg px-4 me-md-2 shadow-none">START BROWSING</a> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero