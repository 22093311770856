import { useNavigate, NavLink } from 'react-router-dom'

import logo from '../../../assets/logo.png'
import LayoutStyles from '../../../assets/css/Layout.css'

import AuthButtons from './AuthButtons'
import MyAccount from './MyAccount'
import Products from './Products'
import InfoBanner from './InfoBanner'

import useIsAuthenticated from '../../../customhooks/useIsAuthenticated'

const NavigationBar = () => {
    const navigate = useNavigate()
    const isAuthenticated = useIsAuthenticated()

    const isActive = (path) => {
        return window.location.pathname === path && 'active'
    }

    const handleNavigation = (destination) => {
        navigate(`${destination}`)
        window.location.reload()
    }
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-text navbar-dark">
                <div className="container-fluid">
                    <button className="navbar-brand" onClick={() => handleNavigation('/')}>
                        <img src={logo} alt="logo" width="156" height="30" />

                        {/* <h3 className="h3" style={{ color: '#3C79F5' }}>magicfans</h3> */}
                    </button>
                    <button className="navbar-toggler shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 navbar-txt">

                            <li className={'nav-item ' + isActive('/')}>
                                <button className={'nav-link ' + isActive('/')} onClick={() => handleNavigation('/')}>Home</button>
                            </li>

                            <li className={'nav-item ' + isActive('/contact')}>
                                <button className={'nav-link ' + isActive('/contact')} onClick={() => handleNavigation('/contact')}>Contact</button>
                            </li>

                            <li className={'nav-item'}>
                                <a className={'nav-link'} href="https://forms.gle/wzKq8qgKvYqjCdfJ7" target="_blank">Request OnlyFans</a>
                            </li>

                            <li className={'nav-item btn-primary special-button'}>
                                <button className={'nav-link'} onClick={() => handleNavigation('/points')}>Points</button>
                            </li>

                            {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Categories
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li ><button className={'dropdown-item ' + isActive('/category/white-girls')} onClick={() => handleNavigation('/category/white-girls')}>Category 1</button></li>
                                <li><button className={'dropdown-item ' + isActive('/category/black-girls')} onClick={() => handleNavigation('/category/black-girls')}>Category 2</button></li>
                                <li><button className={'dropdown-item ' + isActive('/category/light-skinned-girls')} onClick={() => handleNavigation('/category/light-skinned-girls')}>Category 3</button></li>
                                <li><button className={'dropdown-item ' + isActive('/category/latin-girls')} onClick={() => handleNavigation('/category/latin-girls')}>Category 4</button></li>
                                <li><button className={'dropdown-item ' + isActive('/category/asian-girls')} onClick={() => handleNavigation('/category/asian-girls')}>Category 5</button></li>
                            </ul>
                        </li> */}

                            {/* <li className='nav-item'>
                        <a className='nav-link' href='https://magicgen.xyz/' target='_blank'>❗ MagicGen - Free Account Generator</a>
                    </li> */}

                        </ul>

                        <Products />

                        {!isAuthenticated &&
                            <AuthButtons />
                        }

                        {isAuthenticated &&
                            <MyAccount />
                        }

                    </div >
                </div >
            </nav >

            <InfoBanner />
        </>
    )
}

export default NavigationBar