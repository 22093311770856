import config from '../../../config'

import React from 'react'

const Ready = () => {
    return (
        <section className="py-6">
            <div className="container">
                <div className="row justify-content-center text-center mb-4">
                    <div className="col-xl-6 col-lg-8 col-sm-10">
                        <h2 className="fw-bold">Ready to get started?</h2>
                    </div>
                </div>

                <div className="text-center">
                    <a href={config.MAGICPOINTS_WEBSITE_URL + '/register?source=magicfans'} className="btn btn-primary mb-3">Create Account</a>

                    {/* <p>Any Questions? Contact us at <a href="mailto:magicgen@protonmail.com">magicgen@protonmail.com</a></p> */}
                </div>

            </div>
        </section>
    )
}

export default Ready