import React from 'react'

const InfoBanner = () => {
    return (
        <div className="container-fluid text-center">
            <a href={'/points'} className="special-text" style={{ fontWeight: 'bold', textDecoration: 'none' }}>
                Earn or purchase points to bypass ads and download OnlyFans instantly! Click here to learn more.
            </a>
            <hr className="solid" />
        </div>
    )
}

export default InfoBanner