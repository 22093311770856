import { useState, useEffect } from 'react'

import useFiltersParams from '../../../customhooks/useFiltersParams'
import usePage from '../../../stores/usePage'

const SearchBar = () => {
    const setPage = usePage((state) => state.setPage)
    const [getParameterValue, setParameterValue] = useFiltersParams()

    const [searchIsFocused, setSearchIsFocused] = useState(false)

    const [searchFilters, setSearchFilters] = useState({
        keywords: getParameterValue('keywords') || '',
        category: getParameterValue('category') || 'all'
      })

    const handleChange = (e) => {
        setSearchFilters({
            ...searchFilters,
            [e.target.name]: e.target.value
        })
    }

    const handleSearch = () => {
        setPage(1)
        setParameterValue('keywords', searchFilters.keywords)
        setParameterValue('category', searchFilters.category)
        window.scrollTo(0, 0)
      }

    return (
        /* <div className='container px-4 pt-4 sticky-top'> */
        <div className='container px-4 py-4 sticky-top'>
            <div className='col-lg-8 mx-auto'>
                <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                    <input
                        className="form-control shadow-none rounded-4"
                        type="text"
                        placeholder="Keywords"
                        name="keywords"
                        value={searchFilters.keywords}
                        onChange={handleChange}
                        onFocus={() => setSearchIsFocused(true)}
                        onBlur={() => setSearchIsFocused(false)}
                        onKeyDownCapture={(e) => {
                            if (e.key === 'Enter') {
                                handleSearch()
                            }
                        }}
                    />

                    <select
                        className="form-select shadow-none w-auto rounded-4"
                        name="category"
                        defaultValue={searchFilters.category}
                        onChange={handleChange}
                    >
                        <option value="all">All</option>
                        <option value="white-girls">White Girls</option>
                        <option value="light-skinned-girls">Light Skinned Girls</option>
                        <option value="latin-girls">Latin Girls</option>
                        <option value="asian-girls">Asian Girls</option>
                        <option value="black-girls">Black Girls</option>
                    </select>

                    <button
                        className="btn btn-primary btn-lg px-4 gap-3 rounded-4"
                        type="button"
                        onClick={handleSearch}
                    >
                        Search
                    </button>
                </div>
                {searchIsFocused &&
                    <div className="d-flex justify-content-center">
                        {/* <span className="py-2 mt-3 fs-6 text-center text-white badge main-color">Press enter or click the search button</span> */}
                    </div>

                }
            </div>
        </div>
    )
}

export default SearchBar