import config from '../../../config'

import React from 'react'

const HowItWorks = () => {
    return (
        <section className="py-4">
            <div className="container col-xxl-10">
                <div className="row justify-content-center text-center mb-4">
                    <div className="col-xl-6 col-lg-8 col-sm-10">
                        <h2 className="fw-bold">How It Works</h2>
                    </div>
                </div>

                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 text-center justify-content-center px-xl-6 aos-init aos-animate" data-aos="fade-up">
                    <div className="col my-3">
                        <div className="card border-hover-primary hover-scale h-100">
                            <div className="card-body">
                                <div className="text-primary mb-2">
                                    <i className="bi bi-box-arrow-in-right special-text" style={{ fontSize: '50px' }}></i>
                                </div>
                                <h6 className="lead fw-bold">Create an account</h6>
                                <p className="mb-0">Set up a free account that can be used for all our products.<br />
                                    <a href={config.MAGICPOINTS_WEBSITE_URL + '/register?source=magicfans'}>Create Account</a>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col my-3">
                        <div className="card border-hover-primary hover-scale h-100">
                            <div className="card-body">
                                <div className="text-primary mb-2">
                                    <i className="bi bi-person-video3 special-text" style={{ fontSize: '50px' }}></i>
                                </div>
                                <h6 className="lead fw-bold">Earn or Purchase Points</h6>
                                <p className="mb-0">Download OnlyFans to earn points or buy them from our dashboard.<br />
                                    <a className="btn btn-primary special-button" href={config.MAGICPOINTS_WEBSITE_URL + '/buy-points'} target="_blank">Buy Points</a>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col my-3">
                        <div className="card border-hover-primary hover-scale h-100">
                            <div className="card-body">
                                <div className="text-primary mb-2">
                                    <i className="bi bi-coin special-text" style={{ fontSize: '50px' }}></i>
                                </div>
                                <h6 className="lead fw-bold">Redeem Points</h6>
                                <p className="mb-0">Use your points to bypass ads on any OnlyFans content you download. <br />
                                    <a href={config.MAGICPOINTS_WEBSITE_URL + '/learn-more'} target="_blank">Additional Redemption Choices</a>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default HowItWorks