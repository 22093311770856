import config from '../../../config'

import React from 'react'

import image1 from '../assets/image1.png'
import image2 from '../assets/image2.png'
import image3 from '../assets/image3.png'
import image4 from '../assets/image4.png'
import image5 from '../assets/image5.png'
import image6 from '../assets/image6.png'
import image7 from '../assets/image7.png'

import magicpoints_website from '../assets/magicpoints_website.png'

import adfree1 from '../assets/adfree1.png'
import adfree2 from '../assets/adfree2.png'
import adfree3 from '../assets/adfree3.png'
import adfree4 from '../assets/adfree4.png'

const images = [image1, image2, image3, image4, image5, image6, image7]
const adfreeimages = [adfree1, adfree2, adfree3, adfree4]

const HowTo = () => {
    const getRandomImage = (imageList) => {
        const randomIndex = Math.floor(Math.random() * imageList.length)
        return imageList[randomIndex]
    }

    return (
        <section className="py-4">
            {/* <div className="container col-xxl-10 px-4 py-5"> */}
            <div className="container col-xxl-10 px-4">
                {/* <div className="row justify-content-center text-center mb-4">
                    <div className="col-xl-6 col-lg-8 col-sm-10">
                        <h2 className="fw-bold">Many ways to earn</h2>
                    </div>
                </div> */}

                <div className="row flex-lg-row-reverse align-items-center g-5 justify-content-center">
                    <div className="col-10 col-sm-8 col-lg-6">
                        <a href={config.MAGICFANS_WEBSITE_URL} target="_blank">
                            <img src={getRandomImage(images)} className="d-block mx-lg-auto img-fluid rounded" alt="Team" width="700" height="500" loading="lazy" />
                        </a>
                    </div>
                    <div className="col-lg-6">
                        <p className="h2 fw-bold">How to Earn Points</p>
                        <p className="lead">You earn points for each OnlyFans download.</p>
                    </div>
                </div>

                <div className="row flex-lg-row align-items-center g-5 justify-content-center">
                    <div className="col-10 col-sm-8 col-lg-6">
                        <a href={config.MAGICFANS_WEBSITE_URL} target="_blank">
                            <img src={getRandomImage(adfreeimages)} className="d-block mx-lg-auto img-fluid rounded" alt="Team" width="700" height="500" loading="lazy" />
                        </a>
                    </div>
                    <div className="col-lg-6">
                        <p className="h2 fw-bold">How to Use Your Points</p>
                        <p className="lead">Click the Ad-Free button to use your points, skip the ads, and go directly to the download.</p>
                    </div>
                </div>

                <div className="row flex-lg-row-reverse align-items-center g-5 justify-content-center">
                    <div className="col-10 col-sm-8 col-lg-6">
                        <a href={config.MAGICPOINTS_WEBSITE_URL + '/buy-points'} target="_blank">
                            <img src={magicpoints_website} className="d-block mx-lg-auto img-fluid rounded" alt="Team" width="700" height="500" loading="lazy" />
                        </a>
                    </div>
                    <div className="col-lg-6">
                        <p className="h2 fw-bold">How to Buy Points</p>
                        <p className="lead">You also have the option to buy points directly from our dashboard. <br />
                        <a className="btn btn-primary special-button" href={config.MAGICPOINTS_WEBSITE_URL + '/buy-points'} target="_blank">Buy Points</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowTo