import React from 'react'

import faqlist from '../assets/faq.json'

const Faq = () => {
    return (
        <section className="py-4">
            <div className="container">
                <div className="row justify-content-center text-center mb-4">
                    <div className="col-xl-6 col-lg-8 col-sm-10">
                        <h2 className="fw-bold">Frequently Asked Questions</h2>
                    </div>
                </div>

                <div className="accordion accordion-flush" id="myAccordion">

                    {faqlist.map((currentFaq) => {

                        return (
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-headingOne"> <button className="accordion-button collapsed border-0 shadow-none" type="button" data-bs-toggle="collapse" data-bs-target={'#' + currentFaq.id} aria-expanded="false" aria-controls={currentFaq.id}>{currentFaq.question}</button> </h2>
                                <div id={currentFaq.id} className="accordion-collapse collapse border-0" aria-labelledby="flush-headingOne" data-bs-parent="#myAccordion">
                                    <div className="accordion-body">
                                        <p>{currentFaq.answer}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default Faq