import { useState, useEffect } from 'react'

import { NavigationBar, Footer } from '../components/Layout'
import Alert from '../components/Misc/Alert'

import { Hero, HowItWorks, HowTo, Faq, Ready } from '../features/Points'

const Points = () => {
    useEffect(() => {
        document.title = 'MagicFans - Points'
    }, [])

    return (
        <>
            <NavigationBar />
            <Alert />
            <Hero />
            <HowItWorks />
            <HowTo />
            <Faq />
            <Ready />
            <Footer />
        </>
    )
}

export default Points